




































































import Vue from 'vue';
import { mapState } from 'vuex';
import { startCase, camelCase, get } from 'lodash-es';
import $http from 'axios';
import FieldDetails from '@/components/docs/FieldDetails.vue';
import MarkdownItAttrs from 'markdown-it-attrs';
import MarkdownIt from 'markdown-it';
import 'highlight.js/styles/default.css';
import { Endpoint, IEndpointField } from "@/types";
export default Vue.extend({
  name: "Docs",
  components: {
    FieldDetails
  },
  data: () => ({
    source: '',
    selectedUrl: '',
    sampleData: '',
    isLoadingData: false
  }),
  computed: {
    ...mapState('docs', ['endpoints', 'currentEndpoint']),
    endpointData(): Endpoint {
      return this.endpoints.find((e: Endpoint) => e.name === this.currentEndpoint) || null;
    },
    endpointExamples(): string[] {
      return [''].concat(this.endpointData.examples || []);
    },
    activeUrl(): string {
      return this.parseUrl(this.selectedUrl);
    }
  },
  watch: {
    '$route.query.endpoint': {
      handler(endpoint) {
        this.isLoadingData = true;
        this.selectedUrl = '';
        this.isLoadingData = false;
        this.$store.commit('docs/setCurrentEndpoint', endpoint);
        $http.get(`/doc/${endpoint || 'root'}.md`)
            .then(r => {
              this.source = r.data;
            })
            .catch(() => Promise.resolve());
      },
      immediate: true
    },
    activeUrl: {
      handler(url) {
        if (!this.isLoadingData && url) {
          $http.get(url).then(r => {
            this.sampleData = r.data;
          })
              .catch(() => Promise.resolve());
        }
      },
      immediate: true
    }
  },
  mounted() {
    Promise.all([
      '/doc/field_defaults.json',
      '/doc/endpoints.json'
    ].map(url => fetch(url).then(r => r.json())))
        .then(([fields, endpoints]) => {
           this.$store.commit(
              'docs/setEndpoints',
               this.applyDefaults(endpoints, fields).map(e => new Endpoint(e))
          );
        });
    (this.$refs.mdSource as unknown as MarkdownIt).use(MarkdownItAttrs);
    (this.$refs.mdSample as unknown as MarkdownIt).use(MarkdownItAttrs);
  },
  methods: {
    titleCase(str: string): string {
      return startCase(camelCase(str));
    },
    parseUrl(value?: string) {
      return this.endpointData?.name
          ? `/api/${this.endpointData.name}${value}`
          : '';
    },
    applyDefaults(endpoints: any[], fields: IEndpointField[]) {
      return [...endpoints.map(e => ({
        ...e,
        fields: (e.fields || []).map((f: string | IEndpointField) => {
          const name = get(f, 'name') || f;
          const field = fields.find(fld => fld.name === name);
          return typeof f === 'string'
              ? field
              : {
                ...field,
                ...f
              };
        })
      }))];
    }
  }
});

