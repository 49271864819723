var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"field-name"},[_vm._v(" "+_vm._s(_vm.field.name)+" ")]),_c('b-table',{attrs:{"items":[_vm.field],"small":"","stacked":true,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(purpose)",fn:function(ref){
var value = ref.value;
return [_c('markdown-light',{attrs:{"content":value}})]}},{key:"cell(default)",fn:function(ref){
var value = ref.value;
return [_c('markdown-light',{ref:"mdDefault",attrs:{"content":typeof value === 'string' ? value : '```' + value + '```{.hljs}'}})]}},{key:"cell(options)",fn:function(ref){
var value = ref.value;
return [_c('markdown-light',{attrs:{"content":'```\n' + JSON.stringify(value, null, 2) + '\n```'}})]}},{key:"cell(question)",fn:function(ref){
var value = ref.value;
return [_c('user-question',{attrs:{"question":value}})]}},{key:"cell(filter)",fn:function(ref){
var value = ref.value;
return [_c('markdown-light',{attrs:{"content":value}})]}},{key:"cell(format)",fn:function(ref){
var value = ref.value;
return _vm._l((value),function(v,key){return _c('code',{key:key,staticClass:"hljs"},[_vm._v(_vm._s(_vm.parseLink(v)))])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }