


































import Vue from 'vue';
import { IEndpointField } from '@/types';
import { mapState } from 'vuex';
import UserQuestion from '@/components/docs/UserQuestion.vue';
import MarkdownIt from "markdown-it";
import MarkdownItAttrs from "markdown-it-attrs";
export default Vue.extend({
  name: "FieldDetails",
  components: {
    UserQuestion
  },
  props: {
    field: {
      type: Object as () => IEndpointField,
      required: true
    }
  },
  computed: {
    ...mapState('docs', ['currentEndpoint']),
    fields(): string[] {
      return ['purpose', 'options', 'question', 'filter']
        .filter( key => key in this.field)
        .concat(['default', 'format']);
    }
  },
  mounted() {
    (this.$refs.mdDefault as unknown as MarkdownIt)?.use(MarkdownItAttrs);
  },
  methods: {
    parseLink(value: string): string {
      return `/api/${this.currentEndpoint}${value}`;
    },
    parseFormat(values: string[]) {
      return values.map(value => `/api/${this.currentEndpoint}${value}`);
    }
  }
});
