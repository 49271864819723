











import Vue from 'vue';
import { IQuestion } from '@/types';
export default Vue.extend({
  name: "UserQuestion",
  props: {
    question: {
      type: Object as () => IQuestion,
      required: true
    }
  }
});
